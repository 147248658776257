import {
  AdditionalService,
  Community,
  Prices,
  SearchResourceNew,
  SlyPrice
} from '@sly/core/src/types';
import { getAdressDetailsFromAddressString } from '@common/helpers/address';
import { getPricingStringFromCommunityData } from '@react/chat/src/utils/helpers';
import { ADL_OPTIONS_MAP } from '@react/gwizzy/constants';
import { abbreviatePriceWithOptions } from './abbreviatePrice';
import { haversineDistance } from './haversineDistance';
import slugify from 'slugify';
import {
  getLivePartnerAgent,
  isDisclosureEnabledForState
} from './communities';
import { GwizzyPricingThreshold } from '@sly/core/api/types';

interface PricesByCareType {
  [key: string]: {
    roomType: string;
    price: number;
  };
}

export enum ConversionSchemaType {
  Gwizzy = 'gwizzy',
  D2C = 'd2cV3',
  NoAgent = 'noAgent',
  ActiveAdult = 'activeAdult',
  PaidAd = 'paidAd'
}

const roomTypeValueToLabelMap = {
  oneBedroom: 'one bedroom',
  twoBedroom: 'two bedroom',
  privateRoom: 'private room',
  sharedBedroom: 'shared bedroom',
  studio: 'studio',
  studioWithAlcove: 'studio with alcove',
  oneBedroomWithAlcove: 'one bedroom with alcove',
  threeBedroom: 'three bedroom',
  privateCottage: 'private cottage'
};

export interface GwizzySimilarCommunity {
  name: string;
  imagePath: string;
  distance: string | null;
  id: string;
}
export interface GwizzyCommunity {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  city: string;
  state: string;
  timeZone: string;
  partnerAgent: Record<string, any> | null;
  isD2C: boolean;
  communityPhone?: string | undefined | null;
  startingRate?: number | null;
  estimatedPricingString?: string | null;
  pricesByCareType?: PricesByCareType;
  isAutoReferable: boolean;
  similarCommunities?: Array<GwizzySimilarCommunity>;
  primaryToc: string;
  conversionSchemaType: ConversionSchemaType;
  isDisclosureEnabled: boolean;
  care: string[];
  imagePath: string;
  managementCompany?: string;
  isD2CAdjacent?: boolean;
  gwizzyPricingThreshold?: GwizzyPricingThreshold[] | null;
}

const pricesByCareTypeMultiplier = {
  [ADL_OPTIONS_MAP.memoryCare.value]: 1.2,
  [ADL_OPTIONS_MAP.lightCare.value]: 1,
  [ADL_OPTIONS_MAP.heavyCare.value]: 1.1,
  [ADL_OPTIONS_MAP.noCare.value]: 1
};

export const tocToAdlMap = {
  'assisted-living': ADL_OPTIONS_MAP.lightCare.value,
  'memory-care': ADL_OPTIONS_MAP.memoryCare.value,
  'independent-living': ADL_OPTIONS_MAP.noCare.value
};

function getFallbackPriceFromStartingRate(startingRate: number | null) {
  if (!startingRate) return {};
  return Object.entries(pricesByCareTypeMultiplier).reduce((acc, curr) => {
    const [careType, multiplier] = curr;
    const price = startingRate ? startingRate * multiplier : 0;
    acc[careType] = { roomType: 'unknown', price };
    return acc;
  }, {} as PricesByCareType);
}

function getPricesByCareTypeFromSlyPrices(slyPrices: SlyPrice[]) {
  return slyPrices.reduce((acc, price) => {
    const { title } = price;
    const prices = price?.prices || {};
    const { memoryCare, lightCare, heavyCare, noCare } = ADL_OPTIONS_MAP;

    const roomTypes =
      (Object.keys(prices).filter(price =>
        Object.keys(roomTypeValueToLabelMap).includes(price)
      ) as Array<keyof Prices>) || [];

    const availablePrices = roomTypes
      .filter(roomType => {
        const priceByRoomType = prices[roomType] as AdditionalService;
        return (
          priceByRoomType &&
          priceByRoomType?.type !== 'disabled' &&
          Number(priceByRoomType?.from) > 0
        );
      })
      .map(roomType => {
        const priceByRoomType = prices[roomType] as AdditionalService;
        return {
          roomType: roomType,
          price: Number(priceByRoomType?.from)
        };
      });

    if (availablePrices.length === 0) return acc;

    const lowestPriceRoom = availablePrices.reduce((prev, curr) =>
      curr.price < prev.price ? curr : prev
    );

    if (title === 'Assisted Living') {
      acc[lightCare.value] = lowestPriceRoom;
      acc[heavyCare.value] = lowestPriceRoom;
    }

    if (title === 'Memory Care') {
      acc[memoryCare.value] = lowestPriceRoom;
    }

    if (title === 'Independent Living') {
      acc[noCare.value] = lowestPriceRoom;
    }

    return acc;
  }, {} as PricesByCareType);
}

function getPricesByCareType({
  startingRate,
  slyPrices
}: {
  startingRate: number | null;
  slyPrices?: SlyPrice[];
}) {
  const fallbackPrice = getFallbackPriceFromStartingRate(startingRate);

  if (!(slyPrices && slyPrices?.length)) return fallbackPrice;

  const formattedPrice = getPricesByCareTypeFromSlyPrices(slyPrices);

  // populate missing values from fallbackPrice
  const finalPrices = { ...fallbackPrice, ...formattedPrice };
  return finalPrices;
}

export function getPricingStringForCareType({
  pricesByCareType,
  careType
}: {
  pricesByCareType: PricesByCareType;
  careType: string;
}) {
  const priceInfo = pricesByCareType[careType];
  if (!priceInfo) {
    return {};
  }

  const { roomType, price } = priceInfo;

  if (price < 1) {
    return {};
  }

  const minPrice = abbreviatePriceWithOptions(Math.floor(price * 0.9), {
    notation: 'standard'
  });

  const priceAbbr = abbreviatePriceWithOptions(Math.floor(price * 1.1), {
    notation: 'standard'
  });

  if (
    roomType === 'unknown' ||
    !roomTypeValueToLabelMap[roomType as keyof typeof roomTypeValueToLabelMap]
  ) {
    return {
      heading: `Based on your care level the estimated cost for this community is between:`,
      minPrice: minPrice,
      price: priceAbbr
    };
  }

  return {
    heading: `Based on your specified care level the estimated cost for a ${
      roomTypeValueToLabelMap[roomType as keyof typeof roomTypeValueToLabelMap]
    } in this community is between:`,
    minPrice: minPrice,
    price: priceAbbr
  };
}

export function transformGwizzyCommunityFromCommunityModel(
  community: Community,
  isD2CGwizzyEnabled: boolean,
  gwizzyPricingThreshold: GwizzyPricingThreshold[] | null | undefined
): GwizzyCommunity | null {
  if (!community) return null;

  const {
    address,
    id,
    name,
    partnerAgents,
    propInfo,
    startingRate,
    slyPrices,
    similarCommunities,
    care,
    gallery
  } = community;

  const { recommended } = similarCommunities || {};
  const { city, state, latitude, longitude, timeZone } = address || {};

  const estimatedPricingString = getPricingStringFromCommunityData(community);

  const { communityPhone } = propInfo;

  const partnerAgent = getLivePartnerAgent(partnerAgents);
  const rgsInfo = community?.rgsAux?.rgsInfo;
  const isAutoReferable = !!rgsInfo?.contract_info?.isAutoReferable;
  const isD2CAdjacent = !!rgsInfo?.isD2CAdjacent;
  const isLuxury = !!propInfo?.isLuxury;
  const isActiveAdult = care && care.includes('Active Adult Communities (55+)');

  const pricesByCareType = getPricesByCareType({ startingRate, slyPrices });

  const primaryToc = care?.length ? slugify(care[0], { lower: true }) : '';

  let conversionSchemaType: ConversionSchemaType = ConversionSchemaType.Gwizzy;

  if (isActiveAdult) {
    conversionSchemaType = ConversionSchemaType.ActiveAdult;
  } else if (isD2CGwizzyEnabled) {
    if (isAutoReferable || isD2CAdjacent || isLuxury) {
      conversionSchemaType = ConversionSchemaType.D2C;
    } else if (!partnerAgent) {
      conversionSchemaType = ConversionSchemaType.NoAgent;
    }
  }

  return {
    id,
    name,
    latitude,
    longitude,
    city,
    state,
    timeZone: timeZone || 'America/Los_Angeles',
    partnerAgent,
    isD2C: (isAutoReferable || isD2CAdjacent || isLuxury) && isD2CGwizzyEnabled,
    communityPhone,
    startingRate,
    estimatedPricingString,
    pricesByCareType,
    isAutoReferable,
    similarCommunities: recommended?.map(c => {
      return {
        id: c.id,
        name: c.name,
        imagePath: c.gallery?.images?.[0]?.path || '',
        distance:
          !!longitude && !!latitude
            ? haversineDistance(
                [c.longitude, c.latitude],
                [longitude, latitude],
                true
              ).toFixed(2)
            : null
      };
    }),
    primaryToc,
    conversionSchemaType,
    isDisclosureEnabled: isAutoReferable && isDisclosureEnabledForState(state),
    care,
    imagePath: gallery?.images?.[0]?.path || '',
    managementCompany: propInfo?.managementCompany,
    isD2CAdjacent: !!isD2CAdjacent,
    gwizzyPricingThreshold
  };
}

export function transformGwizzyCommunityFromSearchResourceModel(
  searchResource: SearchResourceNew
): GwizzyCommunity | null {
  if (!searchResource) return null;
  const {
    id,
    name,
    latitude,
    longitude,
    timeZone,
    addressString,
    startingRate,
    searchResourceData,
    secondLine,
    gallery
  } = searchResource;
  const { city, state } = getAdressDetailsFromAddressString(addressString);
  const { communitySearchData } = searchResourceData || {};
  const { isD2C, isD2CAdjacent, isAgentArea } = communitySearchData || {};
  const pricesByCareType = getFallbackPriceFromStartingRate(startingRate);

  const care = secondLine ? secondLine.split(' \u00B7 ') : [];
  const isActiveAdult = care && care.includes('Active Adult Communities (55+)');

  const primaryToc = care?.length ? slugify(care[0], { lower: true }) : '';

  let conversionSchemaType: ConversionSchemaType = ConversionSchemaType.Gwizzy;

  if (isActiveAdult) {
    conversionSchemaType = ConversionSchemaType.ActiveAdult;
  } else if (!isAgentArea) {
    conversionSchemaType = ConversionSchemaType.NoAgent;
  }

  return {
    id,
    name,
    latitude,
    longitude,
    city,
    state,
    timeZone: timeZone || 'America/Los_Angeles',
    partnerAgent: null,
    isD2C: !!isD2C || !!isD2CAdjacent,
    startingRate,
    pricesByCareType,
    isAutoReferable: !!isD2C,
    primaryToc,
    conversionSchemaType,
    isDisclosureEnabled: !!isD2C && isDisclosureEnabledForState(state),
    care,
    imagePath: gallery?.images?.[0]?.path || '',
    isD2CAdjacent: !!isD2CAdjacent
  };
}
