/** @jsxImportSource react */
import {
  getGwizzyCustomOptions,
  getIsSupplementalIncomeRequired,
  getIsValidCustomBudgetOption,
} from '@common/helpers/getCustomBudgetOptions';
import { phoneFormatter, phoneParser } from '@common/helpers/phone';
import {
  composeValidators,
  email,
  required,
  usPhone,
} from '@react/form/helpers/validation';
import {
  TocComponent,
  TocEmailComponent,
} from '@react/gwizzy/components/GwizzyTocComponent';
import {
  ACTIONS as actions,
  BUDGET_MAP as budgetMap,
  CONVERSION_FIELDS as fields,
  CONVERSION_STEPS_MAP as steps,
  DEFAULT_BUDGET_MAP,
} from '@react/gwizzy/constants';
import { isSelf, shouldAskResNameInPreConv } from '@react/gwizzy/helpers/utils';

import MismatchWarning from '../components/MismatchWarning';

export const getConversionSchema = ({
  community,
  showLocationStep = false,
  isReadOnlyEmail = false,
  isChatMode = false,
  isResidentNameQuestionRequired,
  submitTextCTA,
}) => {
  const submitText = submitTextCTA
    ? submitTextCTA
    : community
    ? 'Send me pricing'
    : 'Get recommendations';
  const shouldCaptureEmailLead = true;

  const budgetRangeOptions = getGwizzyCustomOptions(
    community?.gwizzyPricingThreshold,
    DEFAULT_BUDGET_MAP
  );

  return {
    [steps.lookingFor]: {
      form: {
        getQuestion: () =>
          isChatMode
            ? 'I am happy to gather pricing details for you. Are you looking for senior living options for yourself or someone else?'
            : 'Are you looking for senior living options for yourself or someone else?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.careLevel,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.lookingFor.options,
            name: fields.lookingFor.name,
            type: 'boxChoice',
            onChangeHandler: ({ useForm }) => {
              const { change } = useForm;
              change(fields.timing.name, null);
            },
            validate: required,
            hasRadio: !isChatMode,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Answering a few questions will help our advisors give you more
              personalized pricing and recommendations.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.careLevel]: {
      form: {
        getQuestion: ({ values }) =>
          isSelf(values)
            ? 'Do you need help with any of the following?'
            : 'Does the person you are looking for need help with any of the following?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.moveTimeline,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.adl.options,
            name: fields.adl.name,
            type: 'boxChoice',
            hasRadio: !isChatMode,
            validate: required,
          },
        ],
        footer: {
          FormFooter: ({ values }) => {
            const { adl } = values || {};
            const care = [...(community?.care ?? [])];

            return (
              <MismatchWarning
                city={community?.city}
                state={community?.state}
                adl={adl}
                care={care}
              />
            );
          },
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Your pricing will vary depending on room type, services, and the
              level of care needed.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.moveTimeline]: {
      form: {
        getQuestion: ({ values }) =>
          `What is ${isSelf(values) ? 'your' : 'their'} move timeline?`,
        getActions: () => ({
          onNext: {
            goTo: (_) => steps.budgetRange,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.timing.options,
            name: fields.timing.name,
            type: 'boxChoice',
            hasRadio: !isChatMode,
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Understanding your timeline will help our advisors recommend
              communities that have availability.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.budgetRange]: {
      form: {
        getQuestion: ({ values }) =>
          `What is ${isSelf(values) ? 'your' : 'their'} monthly budget?`,
        getActions: () => ({
          onNext: {
            goTo: ({ budgetRange }) => {
              if (
                getIsSupplementalIncomeRequired(budgetRangeOptions, budgetRange)
              ) {
                return steps.budget;
              }

              return showLocationStep ? steps.userLocation : steps.name;
            },
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: (_) => budgetRangeOptions,
            name: fields.budgetRange.name,
            type: 'boxChoice',
            hasRadio: !isChatMode,
            onChangeHandler: ({ useForm }) => {
              const { change } = useForm;
              change(fields.budget.name, null);
              change(
                'isCustomBudgetRange',
                getIsValidCustomBudgetOption(community?.gwizzyPricingThreshold)
                  ? 'yes'
                  : 'no'
              );
            },
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Understanding your budget can help us prepare a list of
              recommended communities that fit both your care needs and your
              budget.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.budget]: {
      form: {
        getQuestion: ({ values }) =>
          `Do ${
            isSelf(values) ? 'you' : 'they'
          } have access to any of the financial resources?`,
        formLabel: 'Select all that apply.',
        getActions: () => ({
          onNext: {
            goTo: () => (showLocationStep ? steps.userLocation : steps.name),
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.budget.options,
            name: fields.budget.name,
            type: 'boxChoice',
            multiChoice: true,
            validate: required,
            parse: (value) => {
              const o = budgetMap.noAccess;
              const newValue = value?.[value?.length - 1];
              return o === newValue ? [o] : value.filter((v) => v !== o);
            },
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>These may help pay for the monthly costs of living.</div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.userLocation]: {
      form: {
        getQuestion: () => 'Where are you looking for senior living options?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.name,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            name: fields.location.name,
            type: 'locationSearch',
            validate: required,
            isUsingDefaultCurrentLocationHandler: false,
            types: ['administrative_area_level_3', 'locality', 'postal_code'],
            placeholder: 'Enter your city or zip code',
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              This will help us find communities that are close to you and your
              loved one.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.name]: {
      form: {
        getQuestion: () => 'What is your first and last name?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.email,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fieldWrapperClassName: isChatMode
          ? 'space-y-2'
          : 'flex flex-col md:flex-row gap-3 md:child:w-1/2',
        fields: [
          {
            name: 'firstName',
            type: 'text',
            label: 'First name',
            placeholder: 'First name',
            validate: required,
          },
          {
            name: 'lastName',
            type: 'text',
            label: 'Last name',
            placeholder: 'Last name',
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Let us know your full name. We are real people helping real
              people.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.email]: {
      form: {
        getQuestion: () => 'What is your email address?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.phone,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
            asyncHandlerProps: {
              shouldSendUUIDAction: shouldCaptureEmailLead,
            },
            // validations: [{ name: 'email', error: 'Fake email detected' }],
          },
        }),
        fields: [
          {
            name: 'email',
            type: 'email',
            label: 'Email address',
            placeholder: 'Email address',
            validate: composeValidators(email, required),
            readOnly: isReadOnlyEmail,
          },
        ],
        footer: {
          FormFooter: () => <TocEmailComponent />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>We will send you pricing information over email.</div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.phone]: {
      form: {
        getQuestion: () => 'What is your phone number? ',
        getActions: () => ({
          onNext: {
            goTo: () => steps.homeSaleInterest,
            getType: () => actions.setActivePage,
            text: submitText,
            variant: 'primary',
            asyncHandlerProps: {
              shouldSendUUIDAction: true,
              shouldCreateUser: true,
            },
            validations: [
              { name: 'phone', error: 'Fake phone number detected' },
            ],
          },
        }),
        fields: [
          {
            name: 'phone',
            type: 'text',
            label: 'Phone number',
            validate: composeValidators(usPhone, required),
            parse: phoneParser,
            format: phoneFormatter,
          },
        ],
        footer: {
          FormFooter: () => <TocComponent submitText={submitText} />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <>
            <div>
              Our Seniorly Local Advisors will use this information to ensure
              you get the most up-to-date information on pricing and
              availability.
            </div>
          </>
        ),
        heading: 'WHY THIS IS IMPORTANT:',
      },
    },
    [steps.homeSaleInterest]: {
      form: {
        getQuestion: () => 'Are you planning to sell your home?',
        formLabel:
          'Over 70% of older adults will use funds from the sale of a home to help fund the transition to senior living.',
        getActions: () => ({
          onNext: {
            goTo: () => steps.residentName,
            getType: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? actions.setActivePage
                : actions.submit;
            },
            getText: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? 'Continue'
                : 'Submit';
            },
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.homeSaleInterest.options,
            name: fields.homeSaleInterest.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.residentName]: {
      form: {
        getQuestion: () =>
          'What is the name of the person you are searching for?',
        getActions: () => {
          return {
            onNext: {
              type: actions.submit,
              text: 'Submit',
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            name: 'residentName',
            type: 'text',
            label: 'Resident Name',
            validate: required,
          },
        ],
      },
    },
  };
};
