/** @jsxImportSource react */
import {
  getGwizzyCustomOptions,
  getIsSupplementalIncomeRequired,
  getIsValidCustomBudgetOption,
} from '@common/helpers/getCustomBudgetOptions';
import { phoneFormatter, phoneParser } from '@common/helpers/phone';
import {
  getPricingStringForCareType,
  GwizzyCommunity,
} from '@common/helpers/transformGwizzyCommunity';
import {
  composeValidators,
  email,
  required,
  usPhone,
} from '@react/form/helpers/validation';
import {
  TocComponent,
  TocEmailComponent,
} from '@react/gwizzy/components/GwizzyTocComponent';
import TipComponent from '@react/gwizzy/components/TipComponent';
import {
  ACTIONS as actions,
  BUDGET_MAP as budgetMap,
  communityInfoMap,
  CONVERSION_FIELDS as fields,
  CONVERSION_STEPS_MAP as steps,
  DEFAULT_BUDGET_MAP,
  nextStepsMapForD2c as nextStepsMap,
} from '@react/gwizzy/constants';
import {
  getCommunityToggleOptions,
  isSelf,
  shouldAskResNameInPreConv,
} from '@react/gwizzy/helpers/utils';
import { tourDateFilter, tourTimeOptions } from '@sly/core/helpers/date';

import MismatchWarning from '../components/MismatchWarning';
import { FormADL, FormSchema } from '../types';

export function getD2CSchema({
  community,
  showLocationStep = false,
  isReadOnlyEmail = false,
  isChatMode = false,
  isResidentNameQuestionRequired,
  submitTextCTA,
}: {
  community?: GwizzyCommunity;
  showLocationStep?: boolean;
  isReadOnlyEmail?: boolean;
  isChatMode?: boolean;
  isResidentNameQuestionRequired?: boolean;
  submitTextCTA?: string;
}): FormSchema {
  const submitText = submitTextCTA
    ? submitTextCTA
    : community
    ? 'Send me pricing'
    : 'Get recommendations';
  const shouldCaptureEmailLead = true;
  const pricesByCareType = community?.pricesByCareType;

  const getHasValidPricing = (adl: string) => {
    const priceByCareType = pricesByCareType?.[adl];
    if (!priceByCareType || !(priceByCareType?.price > 0)) {
      return false;
    }
    return true;
  };

  const hasRadio = !isChatMode;

  const similarCommunitiesOptions = getCommunityToggleOptions(
    community?.similarCommunities
  );

  const budgetRangeOptions = getGwizzyCustomOptions(
    community?.gwizzyPricingThreshold,
    DEFAULT_BUDGET_MAP
  );

  return {
    [steps.careLevel]: {
      form: {
        getQuestion: () =>
          'Does the person you are looking for need help with any of the following?',
        getActions: () => ({
          onNext: {
            goTo: () =>
              showLocationStep ? steps.userLocation : steps.nextStep,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.adl.options,
            name: fields.adl.name,
            type: 'singleChoice',
            hasRadio,
            validate: required,
          },
        ],
        footer: {
          FormFooter: ({ values }: { values: FormADL }) => {
            const { adl } = values || {};
            const care = [...(community?.care ?? [])];

            return (
              <>
                <MismatchWarning
                  city={community?.city}
                  state={community?.state}
                  adl={adl}
                  care={care}
                />
              </>
            );
          },
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            Your pricing will vary depending on room type, services, and the
            level of care needed.
          </TipComponent>
        ),
      },
    },
    [steps.userLocation]: {
      form: {
        getQuestion: () => 'Where are you looking for senior living options?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.nextStep,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            name: fields.location.name,
            type: 'locationSearch',
            validate: required,
            isUsingDefaultCurrentLocationHandler: false,
            types: ['administrative_area_level_3', 'locality', 'postal_code'],
            placeholder: 'Enter your city or zip code',
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            This will help us find communities that are close to you and your
            loved one.
          </TipComponent>
        ),
      },
    },
    [steps.nextStep]: {
      form: {
        HeaderComponent: ({ values }) => {
          const { heading, price, minPrice } = pricesByCareType
            ? getPricingStringForCareType({
                pricesByCareType,
                careType: values?.adl,
              })
            : {
                heading: '',
                price: null,
                minPrice: null,
              };

          if (!price || !minPrice) {
            return null;
          }

          return (
            <div className='my-4 rounded bg-slate-lighter-90 p-4'>
              <div className='space-y-2'>
                <p>{heading}</p>
                <p className='font-b-l font-medium'>
                  {minPrice} - {price} per month
                </p>
              </div>
            </div>
          );
        },
        getQuestion: () => 'Would you like to discuss this in detail?',
        getActions: () => ({
          onNext: {
            goTo: ({ nextStep, adl }) => {
              switch (nextStep) {
                case nextStepsMap.requestTour.value:
                  return steps.tour;
                case nextStepsMap.none.value:
                  return steps.budgetRange;
                case nextStepsMap.preQualify.value:
                  return steps.budgetRange;
                case nextStepsMap.talk.value:
                  return steps.communityInfo;
                case nextStepsMap.detailedPricingBreakDown.value:
                  return steps.name;
                default: {
                  if (!getHasValidPricing(adl)) {
                    return steps.budgetRange;
                  } else {
                    return steps.name;
                  }
                }
              }
            },
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () =>
              fields.nextStepD2c.options.reduce((acc, { value, label }) => {
                if (
                  value === nextStepsMap.none.value &&
                  !similarCommunitiesOptions.length
                ) {
                  return acc;
                }
                return [
                  ...acc,
                  {
                    value,
                    label,
                  },
                ];
              }, [] as { value: string; label: string }[]),
            name: fields.nextStep.name,
            type: 'singleChoice',
            onChangeHandler: ({ useForm }) => {
              const { change, batch } = useForm;
              batch(() => {
                change(fields.timePreference.name, null);
                change(fields.tourTime.name, null);
                change(fields.budgetRange.name, null);
                change(fields.budget.name, null);
                change(fields.communityInfo.name, null);
                change(fields.communityInfoOther.name, null);
                change(fields.similarCommunities.name, null);
              });
            },
            hasRadio,
            validate: required,
          },
        ],
      },
    },

    [steps.tour]: {
      form: {
        getQuestion: () => 'Do you want to schedule a tour?',
        getActions: () => ({
          onNext: {
            goTo: ({ adl }) => {
              if (!getHasValidPricing(adl)) {
                return steps.budgetRange;
              }
              return steps.name;
            },
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            name: fields.timePreference.name,
            type: 'date',
            label: 'Select a date',
            validate: required,
            filterDate: tourDateFilter,
          },
          {
            name: fields.tourTime.name,
            type: 'choice',
            label: `Select a time`,
            getOptions: () => tourTimeOptions,
            validate: required,
          },
        ],
        fieldWrapperClassName: 'min-h-[50vh] xl:min-h-[30vh]',
      },
    },
    [steps.budgetRange]: {
      form: {
        getQuestion: () =>
          'What is the total cost of care you believe you can afford?',
        getActions: () => ({
          onNext: {
            goTo: ({ budgetRange, nextStep }) => {
              if (
                getIsSupplementalIncomeRequired(budgetRangeOptions, budgetRange)
              ) {
                return steps.budget;
              }
              if (nextStep === nextStepsMap.none.value) {
                return steps.similarCommunities;
              }
              return steps.name;
            },

            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: (_) => budgetRangeOptions,
            name: fields.budgetRange.name,
            type: 'singleChoice',
            hasRadio,
            onChangeHandler: ({ useForm }) => {
              const { change } = useForm;
              change(fields.budget.name, null);
              change(
                'isCustomBudgetRange',
                getIsValidCustomBudgetOption(community?.gwizzyPricingThreshold)
                  ? 'yes'
                  : 'no'
              );
            },
            validate: required,
          },
        ],
        footer: {
          FormFooter: () => (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              Less than 2% of communities in your area are less than $2,000. A
              larger budget will give you more options.
            </div>
          ),
        },
        // fieldWrapperClassName:
        //   'md:[&_.input-wrapper]:grid md:[&_.input-wrapper]:grid-cols-2 md:[&_.input-wrapper]:gap-2',
      },
    },
    [steps.budget]: {
      form: {
        getQuestion: ({ values }) =>
          `Do ${
            isSelf(values) ? 'you' : 'they'
          } have access to any of the financial resources?`,
        formLabel: 'Select all that apply.',
        getActions: () => ({
          onNext: {
            goTo: ({ nextStep }) => {
              if (nextStep === nextStepsMap.none.value) {
                return steps.similarCommunities;
              }
              return steps.name;
            },
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.budget.options,
            name: fields.budget.name,
            type: 'multiChoice',
            multiChoice: true,
            validate: required,
            parse: (value: Array<string>) => {
              const o = budgetMap.noAccess;
              const newValue = value?.[value?.length - 1];
              return o === newValue ? [o] : value.filter((v) => v !== o);
            },
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            These may help pay for the monthly costs of living.
          </TipComponent>
        ),
      },
    },
    [steps.communityInfo]: {
      form: {
        getQuestion: () => 'What do you want to know about this community?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.name,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.communityInfo.options,
            name: fields.communityInfo.name,
            type: 'multiChoice',
            hasRadio,
            multiChoice: true,
            validate: required,
          },
          {
            name: fields.communityInfoOther.name,
            type: 'text',
            label: 'What else do you want to know?',
            validate: required,
            hidden: ({ values }) =>
              !(
                values?.communityInfo?.length &&
                values.communityInfo.includes(communityInfoMap.somethingElse)
              ),
          },
        ],
      },
    },

    [steps.similarCommunities]: {
      form: {
        getQuestion: () =>
          'Get pricing quoutes from these similar communities nearby',
        getActions: () => ({
          onNext: {
            goTo: () => steps.name,
            type: actions.setActivePage,
            text: 'Request a call for more pricing/info',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => similarCommunitiesOptions,
            name: fields.similarCommunities.name,
            type: 'communityToggle',
            hasRadio,
            multiChoice: true,
            validate: required,
          },
        ],
      },
    },

    [steps.name]: {
      form: {
        getQuestion: () => 'What is your first and last name?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.email,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            name: 'firstName',
            type: 'text',
            label: 'First name',
            placeholder: 'First name',
            validate: required,
          },
          {
            name: 'lastName',
            type: 'text',
            label: 'Last name',
            placeholder: 'Last name',
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            Let us know your real name. We are real people helping real people
          </TipComponent>
        ),
      },
    },
    [steps.email]: {
      form: {
        getQuestion: () => 'What is your email address?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.phone,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
            asyncHandlerProps: {
              shouldSendUUIDAction: shouldCaptureEmailLead,
            },
            validations: [{ name: 'email', error: 'Invalid email detected' }],
          },
        }),
        fields: [
          {
            name: 'email',
            type: 'email',
            label: 'Email address',
            placeholder: 'Email address',
            validate: composeValidators(email, required),
            readOnly: isReadOnlyEmail,
          },
        ],
        footer: {
          FormFooter: () => <TocEmailComponent />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            We will send you pricing information over email.
          </TipComponent>
        ),
      },
    },
    [steps.phone]: {
      form: {
        getQuestion: () => 'What is your phone number? ',
        getActions: () => ({
          onNext: {
            goTo: () => steps.homeSaleInterest,
            getType: () => actions.setActivePage,
            text: submitText,
            variant: 'primary',
            asyncHandlerProps: {
              shouldCreateUser: true,
              shouldSendUUIDAction: true,
            },
            validations: [
              { name: 'phone', error: 'Fake phone number detected' },
            ],
          },
        }),
        fields: [
          {
            name: 'phone',
            type: 'text',
            label: 'Phone number',
            validate: composeValidators(usPhone, required),
            parse: phoneParser,
            format: phoneFormatter,
          },
        ],
        footer: {
          FormFooter: () => <TocComponent submitText={submitText} />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            In case we need more information about you we will reach out by
            phone
          </TipComponent>
        ),
      },
    },
    [steps.homeSaleInterest]: {
      form: {
        getQuestion: () => 'Are you planning to sell your home?',
        formLabel:
          'Over 70% of older adults will use funds from the sale of a home to help fund the transition to senior living.',
        getActions: () => ({
          onNext: {
            goTo: () => steps.residentName,
            getType: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? actions.setActivePage
                : actions.submit;
            },
            getText: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? 'Continue'
                : 'Submit';
            },
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.homeSaleInterest.options,
            name: fields.homeSaleInterest.name,
            type: 'boxChoice',
            hasRadio: true,
            validate: required,
          },
        ],
      },
    },
    [steps.residentName]: {
      form: {
        getQuestion: () =>
          'What is the name of the person you are searching for?',
        getActions: () => {
          return {
            onNext: {
              type: actions.submit,
              text: 'Submit',
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            name: 'residentName',
            type: 'text',
            label: 'Resident Name',
            validate: required,
          },
        ],
      },
    },
  };
}
