import { AA, CCRC, TOCS } from '@sly/core/constants/filters';

export const COST_CALCULATOR_TOC_OPTIONS = Object.entries(TOCS).reduce(
  (acc, [key, value]) => {
    if (![CCRC, AA].includes(key)) {
      acc.push({
        label: value.label,
        value: value.value,
      });
    }
    return acc;
  },
  [] as { label: string; value: string }[]
);

export const careLevelToTocMap = {
  'memory-care': 'memory-care',
  'light-care': 'independent-living',
  'heavy-care': 'assisted-living',
  'no-care': 'independent-living',
};

export const tocToCareLevelMap = {
  'memory-care': 'memory-care',
  'independent-living': 'no-care',
  'assisted-living': 'heavy-care',
};

export const ROOM_OPTIONS = [
  {
    label: 'Private',
    value: 'private',
  },
  {
    label: 'Shared',
    value: 'shared',
  },
  {
    label: 'Studio',
    value: 'studio',
  },
];

export const MOBILITY_OPTIONS = [
  // no-issues
  {
    label: 'No Issues',
    value: 'no-issues',
  },
  // Walker
  {
    label: 'Walker',
    value: 'walker',
  },
  // Wheelchair
  {
    label: 'Wheelchair',
    value: 'wheelchair',
  },
  // Bedridden
  {
    label: 'Bedridden',
    value: 'bedridden',
  },
];
