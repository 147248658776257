/** @jsxImportSource react */
import { phoneFormatter } from '@common/helpers/phone';
import Accordian from '@react/shared/components/Accordian';
import AgentCard from '@react/shared/components/AgentCard';
import { Button } from '@react/system';
import { CheckmarkCircle } from '@sly/icons/react';

const getWhatHappensNext = (variant, partnerAgent) => {
  const sentToText = partnerAgent
    ? 'your Seniorly Local Advisor'
    : 'one of our partners';
  let whatHappensNext = '';
  switch (variant) {
    case 'pricing':
      whatHappensNext = `We’ve sent your pricing request to ${sentToText}, who can help you narrow down options, schedule tours, assist with paperwork, and ensure you're making the best decision for your needs. All at no cost to you.`;

      break;
    case 'tour':
      whatHappensNext = `We’ve sent your tour request to ${sentToText}, who can help schedule tours, assist with paperwork, and ensure you're making the best decision for your needs. All at no cost to you.`;
      break;
    default:
      whatHappensNext = `We’ve sent your question to ${sentToText}, who can answer any questions you may have, help you narrow down options, schedule tours, assist with paperwork, and ensure you're making the best decision for your needs. All at no cost to you.`;
  }
  return whatHappensNext;
};

// convert to typescript
export default function AdvisorConfirmation({
  handleConfirmation,
  partnerAgent,
  clientMeta,
  message = '',
  variant,
  buttonText = 'Continue',
  noAgentMessage = 'Thanks! We will connect you with helpful resources at your shared email!',
}) {
  const { isLowIncome, phoneNumber } = clientMeta;
  const showAdvisorModal = !isLowIncome && !!partnerAgent;
  const { name, info: { displayName } = {} } = partnerAgent || {};
  const agentName = displayName || name;
  const variantText = variant === 'pricing' ? ' with pricing.' : '.';
  const agentText = agentName
    ? `Your advisor ${agentName}`
    : 'A Seniorly partner';

  const heading = `${agentText} will call you ${
    phoneNumber ? 'at ' + phoneFormatter(phoneNumber) : ''
  } to help${variantText}`;

  const faqs = [
    {
      question: 'What happens next?',
      Answer: () => <div>{getWhatHappensNext(variant, partnerAgent)}</div>,
      show: true,
    },
    {
      question: 'What is a Seniorly Local Advisor?',
      Answer: () => (
        <div>
          Seniorly offers a free consultation with a local senior living advisor
          who lives in your area and knows all the information about senior
          living communities near you. So go ahead and ask- they&apos;re here to
          help!
        </div>
      ),
      show: !!partnerAgent,
    },
    {
      question: 'How is Seniorly’s service free?',
      Answer: () => (
        <div>
          We&apos;re compensated by senior living communities if you or a family
          member move into a senior living community, so working with a Seniorly
          local advisor for personalized guidance and recommendations is free,
          with no hidden fees and no obligations.
        </div>
      ),
      show: true,
    },
  ];

  return (
    <>
      {showAdvisorModal ? (
        <div className='bg-white p-6'>
          <div className='flex flex-col items-center'>
            <h1 className='font-t-m mb-4 text-center'>{heading}</h1>
            <div className='rounded bg-green-lighter-90 py-2 px-4 text-center'>
              {message || 'Our service is free with no obligations.'}
            </div>
          </div>
          {partnerAgent && <AgentCard className='mt-6' agent={partnerAgent} />}
          {handleConfirmation && (
            <Button
              onClick={() => handleConfirmation()}
              className='mt-6 w-full '
            >
              Continue
            </Button>
          )}

          <div className='mt-6'>
            {faqs
              .filter((faq) => !!faq.show)
              .map((faq, index) => (
                <div
                  key={faq.question}
                  data-tp-id='gen-div-564fe1a8-cb2f-4b7f-91fd-6b309fda8392'
                >
                  <Accordian title={faq.question} content={faq.answer}>
                    <faq.Answer />
                  </Accordian>
                  {index < faqs.length - 1 && (
                    <hr className='my-4 block border-0 border-t' />
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className='flex h-full min-h-[300px] max-w-full flex-col items-center justify-center gap-3 rounded-md bg-white px-6 pb-6'>
          <CheckmarkCircle className='text-green-base' size='xl' />
          <div className='text-center'>{noAgentMessage}</div>
          {handleConfirmation && (
            <Button
              onClick={() => handleConfirmation()}
              className='mt-6 w-full '
            >
              {buttonText}
            </Button>
          )}
        </div>
      )}
    </>
  );
}
