import {
  FAMILY_INTERESTS_OPTIONS,
  MENTAL_HEALTH_OPTIONS,
} from '@sly/core/constants/families';
import { stateAbbr } from '@sly/core/constants/geo';

export const getLabelValueObjArrayFromObj = <t>(
  obj: Record<keyof t, t[keyof t]>
): Array<{ value: keyof t; label: t[keyof t] }> =>
  Object.entries(obj).reduce((acc, curr) => {
    const [key, value] = curr as [keyof t, t[keyof t]];
    acc.push({ label: value, value: key });
    return acc;
  }, [] as Array<{ label: t[keyof t]; value: keyof t }>);

const getOptions = <t>(arr: Array<t>): Array<{ value: t; label: t }> =>
  arr.map((a) => ({ label: a, value: a }));

export const CONVERSION_STEPS_MAP = {
  lookingFor: 'LookingFor',
  careLevel: 'CareLevel',
  moveTimeline: 'MoveTimeline',
  planningConsultation: 'PlanningConsulation',
  searchReasons: 'SearchReasons',
  searchStage: 'SearchStage',
  budgetRange: 'BudgetRangeInfo',
  budget: 'BudgetInfo',
  nextStep: 'NextStep',
  tour: 'TourInfo',
  moreSteps: 'MoreSteps',
  moreBudgetRange: 'MoreBudgetRangeInfo',
  communityInfo: 'CommunityInfo',
  similarCommunities: 'SimilarCommunities',
  userLocation: 'UserLocation',
  askQuestion: 'AskQuestion',
  name: 'NameInfo',
  email: 'EmailInfo',
  phone: 'PhoneInfo',
  housingOptions: 'HousingOptions',
  homeSaleInterest: 'homeSaleInterest',
  backgroundCheck: 'backgroundCheck',
  residentName: 'ResidentNameInfo',
};

export const LOOKING_FOR = 'lookingFor';
export const ADL = 'adl';
export const TIMING = 'timing';

export const ADL_OPTIONS_MAP = {
  memoryCare: {
    label: 'Memory care (wandering risk, increased confusion)',
    value: 'memory-care',
  },
  lightCare: {
    label: 'Lifestyle management (meals, laundry, medication distribution)',
    value: 'light-care',
  },
  heavyCare: {
    label: 'Advanced care (bathing, dressing, transferring)',
    value: 'heavy-care',
  },
  noCare: {
    label: 'No specific care needs, looking for independent living',
    value: 'no-care',
  },
};

export const MONTHLY_BUDGET_MAP = {
  under2K: '<$2K',
  twoToFiveK: '$2K-$5K',
  over5K: '$5K+',
};

export const DEFAULT_BUDGET_MAP = [
  {
    label: '<$2K',
    value: '<$2K',
    supplementalIncomeRequired: true,
  },
  {
    label: '$2K-$5K',
    value: '$2K-$5K',
    supplementalIncomeRequired: false,
  },
  {
    label: '$5K+',
    value: '$5K+',
    supplementalIncomeRequired: false,
  },
];

export const detailedBudgetMap = {
  under2K: 'Less than $2000',
  twoToThreeK: '$2K - $3K',
  threeToFourK: '$3K - $4K',
  fourToFiveK: '$4K - $5K',
  fiveToSevenK: '$5K - $7K',
  sevenToTenK: '$7K - $10K',
};

export const BUDGET_MAP = {
  vertrans: "Veterans' benefits",
  longTermCareInsurance: 'Longterm care insurance',
  equityInHome: 'Equity in a home',
  pensionPlan: 'Pension plan',
  medicaid: 'Medicaid',
  notSure: "I'm not sure",
  noAccess: "I don't have access to any of these benefits",
};

export const ADVISOR_OPTIONS_MAP = {
  tour: 'I want to tour this/other communities',
  financingOptions: "I'd like info about financing options for senior living",
  homeCare: 'I am also considering in-home care as an option',
  sellingHome: 'I need help selling a home',
  transition:
    "I'm looking for senior living because of a transition out of a hospital or rehab stay",
  noAdditionalHelp: "No, I don't need anything else",
  other: 'Other',
};

export const searchReasonsMap = {
  rehab: {
    label: 'Need to transition out of rehab/hospital',
    value: 'rehab',
  },
  overwhelmed: {
    label: 'I am overwhelmed with care duties and other tasks',
    value: 'overwhelmed',
  },
  needsNotMet: {
    label: 'I am concerned needs are not being met at home',
    value: 'needsNotMet',
  },
  isolation: {
    label: 'I am worried about growing isolation',
    value: 'isolation',
  },
  other: {
    label: 'Other',
    value: 'other',
  },
};

export const searchReasonsForOthersMap = {
  rehab: {
    label: 'Need to transition out of rehab/hospital',
    value: 'rehab',
  },
  overwhelmed: {
    label: 'Overwhelmed with care duties and other tasks',
    value: 'overwhelmed',
  },
  needsNotMet: {
    label: 'Concerned that needs are not being met at home',
    value: 'needsNotMet',
  },
  isolation: {
    label: 'Worried about growing isolation',
    value: 'isolation',
  },
  other: {
    label: 'Other',
    value: 'other',
  },
};

export const searchStageMap = {
  hospital: {
    label: 'In a hospital and need to move',
    value: 'hospital',
  },
  touring: {
    label: 'Actively touring communities',
    value: 'touring',
  },
  startedSearching: {
    label: 'Started searching before and have a list of communities to explore',
    value: 'startedSearching',
  },
  researching: {
    label: 'Generally researching to understand what is available',
    value: 'researching',
  },
};

export const moreStepsMap = {
  exploreOptions: {
    label: 'Explore options on my own',
    value: 'exploreOptions',
  },
  connectToConsultant: {
    label: 'Get a free consultation from a local expert',
    value: 'connectToConsultant',
  },
  askQuestion: {
    label: 'Ask a question',
    value: 'askQuestion',
  },
};

export const nextStepsMap = {
  requestTour: {
    label: "I'd like to tour the property",
    value: 'requestTour',
  },
  talk: {
    label: 'Yes, I would like to talk to the community manager',
    value: 'talk',
  },
  preQualify: {
    label: "I'm worried about cost, see if I qualify",
    value: 'preQualify',
  },
  none: {
    label:
      "I'm not interested in this community, I want to see other communities like this in the area",
    value: 'none',
  },
};

export const nextStepsMapForD2c = {
  detailedPricingBreakDown: {
    label: "I'd like a detailed pricing breakdown",
    value: 'detailedPricingBreakDown',
  },
  ...nextStepsMap,
};

export const HOUSING_OPTIONS = {
  buy: 'Buying a home in a senior community',
  rent: 'Renting a home in a senior community',
  sell: 'Selling your current home',
};

export const communityInfoMap = {
  availability: 'Availability',
  pricingAndPromos: 'Pricing and Promos',
  amenities: 'Amenities',
  care: 'Care',
  financing: 'Financing',
  somethingElse: 'Something else',
};

export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  { label: 'No', value: false },
];

export const LOOKING_FOR_OPTIONS = [
  { label: 'Myself', value: 'Myself' },
  { label: 'Myself and my partner', value: 'Self + Partner' },
  { label: 'Mother', value: 'Mother' },
  { label: 'Father', value: 'Father' },
  { label: 'Both parents', value: 'Parents' },
  { label: 'Someone else', value: 'Someone else' },
];

export const ADVISOR_HELP_OPTIONS = getOptions(
  Object.values(ADVISOR_OPTIONS_MAP)
);

export const CONVERSION_FIELDS = {
  location: {
    name: 'location',
  },
  lookingFor: {
    name: 'lookingFor',
    options: LOOKING_FOR_OPTIONS,
  },
  adl: {
    name: 'adl',
    options: Object.values(ADL_OPTIONS_MAP),
  },
  timing: {
    name: 'timing',
    options: [
      {
        label: 'Immediately',
        value: 'Within 30 Days',
      },
      { label: 'Within 6 months', value: '30-90 Days' },
      {
        label: 'Beyond 6 months from now',
        value: 'Over 180 Days',
      },
      {
        label: 'Unsure',
        value: 'Unsure',
      },
    ],
  },
  planningConsultation: {
    name: 'consultationRequested',
    options: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      { label: 'No', value: 'No' },
    ],
  },
  searchReasons: {
    name: 'searchReasons',
    options: Object.values(searchReasonsMap),
  },
  searchReasonsForOthers: {
    name: 'searchReasons',
    options: Object.values(searchReasonsForOthersMap),
  },
  searchReasonsOther: {
    name: 'searchReasonsOther',
  },
  searchStage: {
    name: 'searchStage',
    options: Object.values(searchStageMap),
  },
  backgroundCheck: {
    name: 'backgroundCheck',
    options: YES_NO_OPTIONS,
  },

  isWithinBudget: {
    name: 'isWithinBudget',
    options: YES_NO_OPTIONS,
  },
  budgetRange: {
    name: 'budgetRange',
    options: getOptions(Object.values(MONTHLY_BUDGET_MAP)),
  },
  budget: {
    name: 'budget',
    options: getOptions(Object.values(BUDGET_MAP)),
  },
  nextStep: {
    name: 'nextStep',
    options: Object.values(nextStepsMap),
  },
  nextStepD2c: {
    name: 'nextStep',
    options: Object.values(nextStepsMapForD2c),
  },
  communityInfo: {
    name: 'communityInfo',
    options: getOptions(Object.values(communityInfoMap)),
  },
  communityInfoOther: {
    name: 'communityInfoOther',
  },
  similarCommunities: {
    name: 'similarCommunities',
  },
  moreSteps: {
    name: 'moreSteps',
    options: Object.values(moreStepsMap),
  },
  moreBudgetRange: {
    name: 'budgetRange',
    options: getOptions(Object.values(detailedBudgetMap)),
  },
  timePreference: {
    name: 'tourDate',
  },
  tourTime: {
    name: 'tourTimeText',
  },
  question: {
    name: 'question',
  },
  name: {
    name: 'name',
  },
  email: {
    name: 'email',
  },
  phone: {
    name: 'phone',
  },
  housingOptions: {
    name: 'housingOptions',
    options: getOptions(Object.values(HOUSING_OPTIONS)),
  },
  homeSaleInterest: {
    name: 'homeSaleInterest',
    options: YES_NO_OPTIONS,
  },
};

export const ACTIONS = {
  setActivePage: 'SET_ACTIVE_PAGE',
  submit: 'SUBMIT',
};

export const POST_CONVERSION_STEPS = {
  communication: 'CommunicationInfo',
  mobilePhone: 'MobilePhoneInfo',
  advisorHelp: 'AdvisorHelpInfo',
  residentName: 'ResidentNameInfo',
  tour: 'TourInfo',
  moreInfo: 'MoreInfo',
};

export const CONTACT_INFO_FIELDS = {
  emailOnly: 'email_only',
  phoneOnly: 'phone_only',
  emailAndPhone: 'email_and_phone',
};

export const postConversionSteps = {
  lookingFor: 'LookingFor',
  residentName: 'ResidentNameInfo',
  age: 'AgeInfo',
  importantPreferences: 'ImportantPreferencesInfo',
  roomPreferences: 'RoomPreferencesInfo',
  mobility: 'MobilityInfo',
  interests: 'InterestsInfo',
  mentalHealth: 'MentalHealthInfo',
  assistances: 'AssistancesInfo',
  searchReasons: 'SearchReasonsInfo',
  moreInfoFromLead: 'MoreInfoLeadInfo',
  worriedAboutCosts: 'WorriedAboutCostsInfo',
  equityInHome: 'EquityInHomeInfo',
  searchStage: 'SearchStageInfo',
};

export const roomPreferencesMap = {
  studio: 'Studio',
  oneBedroom: '1 Bedroom',
  twoBedroom: '2 Bedroom',
  privateSuite: 'Private Suite',
  sharedSuite: 'Shared Suite',
};

export const postConversionFields = {
  age: {
    name: 'age',
  },
  importantPreferences: {
    name: 'importantPreferences',
    options: [
      { label: 'Amenities', value: 'amenities' },
      { label: 'Engagement Programs', value: 'engagement-programs' },
      { label: 'Cleanliness', value: 'cleanliness' },
      { label: 'Dining Services', value: 'dining-services' },
      { label: 'Care services', value: 'care-services' },
      { label: 'Residents', value: 'residents' },
      { label: 'Staff', value: 'staff' },
      { label: 'Other', value: 'other' },
    ],
  },
  importantPreferencesOther: {
    name: 'importantPreferencesOther',
  },
  roomPreferences: {
    name: 'roomPreferences',
    options: getOptions(Object.values(roomPreferencesMap)),
  },
  mobility: {
    name: 'mobility',
    options: [
      { label: 'Fully mobile, have no mobility issues', value: 'no-issues' },
      { label: 'Walker', value: 'walker' },
      { label: 'Cane', value: 'cane' },
      { label: 'Wheelchair', value: 'wheelchair' },
      { label: 'Bedridden', value: 'bedridden' },
    ],
  },
  interests: {
    name: 'interests',
    options: FAMILY_INTERESTS_OPTIONS,
  },
  mentalHealth: {
    name: 'mentalHealth',
    options: MENTAL_HEALTH_OPTIONS,
  },
  assistances: {
    name: 'assistances',
    options: [
      { label: 'Yes, other family members', value: 'family' },
      { label: 'Yes, medical or other professional staff', value: 'pro-staff' },
      { label: "No, It's just me", value: 'self' },
    ],
  },
  moreInfoFromLead: {
    name: 'moreInfoFromLead',
  },
  worriedAboutCosts: {
    name: 'worriedAboutCosts',
    options: YES_NO_OPTIONS,
  },
  equityInHome: {
    name: 'equityInHome',
    options: YES_NO_OPTIONS,
  },
};

export const DisclosureFieldNames = {
  [stateAbbr.Arizona]: {
    acknowledgementField: 'isArizonaDisclosureAcknowledged',
    disclosureTextField: 'arizonaDisclosureText',
    isEnabled: true,
  },
  [stateAbbr.Colorado]: {
    acknowledgementField: 'isColoradoDisclosureAcknowledged',
    disclosureTextField: 'coloradoDisclosureText',
    isEnabled: true,
  },
  [stateAbbr.Oklahoma]: {
    acknowledgementField: 'isOklahomaDisclosureAcknowledged',
    disclosureTextField: 'oklahomaDisclosureText',
    isEnabled: true,
  },
};

export const residentNameMandatoryManagementCompanies = [
  'Belmont Village Senior Living',
];
