import {
  Construction as ConstructionTag2React,
  Verified as Verified2React,
  Best as BestReact
} from '@sly/icons/react';
import { Construction, Verified } from 'sly/icons';
import { formatDateV2 as formatDate } from '@sly/core/helpers/date';
import { stateNames } from '@sly/core/constants/geo';
import {
  ASSISTED_LIVING,
  PLUS_TAG,
  VERIFIED_TAG,
  MEMORY_CARE,
  INDEPENDENT_LIVING,
  SKILLED_NURSING_FACILITY
} from '@sly/core/constants/tags';
import { getHashInRange } from '@common/helpers/utils';
import {
  communityDefaultImageTemplates1x1,
  communityDefaultImageV2Templates
} from '@sly/core/constants/communityPricing';

import { areaCode } from '@common/helpers/phone';
import { assetPath } from '@common/lib/path';
import { SENIORLY_AWARD_YEAR_COMMUNITIES } from '@common/constants/awardText';
import { getPartnerAgent } from './communities';

export const getIsCCRC = community => {
  const { care } = community;
  if (care) {
    return care.includes('Continuing Care Retirement Community(CCRC)');
  }
  return false;
};

export const getFormattedAddress = community => {
  const { line1, line2, city, state, zip } = community?.address || {};
  if (!line1) return '';

  return `${line1}, ${line2}, ${city},
  ${state}
  ${zip}`
    .replace(/, null,/g, ',')
    .replace(/\s/g, ' ')
    .replace(/, ,/g, ', ');
};

export const getFirst4StarReview = community => {
  const { reviews = [] } = community;

  for (const element of reviews) {
    if (element.value >= 4) {
      return element;
    }
  }

  return null;
};

export const getHasContract = community => {
  const { rgsAux, info = {} } = community;
  const hasRgsContract = !!rgsAux?.rgsInfo?.contract_info?.hasContract;
  // When community is sent has a Provider, contract info is stored in the info elsewhere.
  const { hasContract: hasInfoContract } = info;

  const isPending =
    rgsAux?.rgsInfo?.contract_info?.contractStatus === 'Pending';

  return hasRgsContract || hasInfoContract
    ? { hasContract: true, isPending }
    : false;
};

export const getIsSNF = community => {
  const { care } = community;
  if (care) {
    return care.includes('Skilled Nursing Facility');
  }
  return false;
};

export const getIsActiveAdult = community => {
  const { care } = community;
  if (care) {
    return care.includes('Active Adult Communities (55+)');
  }
  return false;
};

export const getIsSellerAgentCTA = community => {
  // const validTocLabels =  tocs.filter(e =>
  //   e.value.match(/active-adult|independent-living|continuing-care-retirement-community/)).map(e => e.label);
  // const { propInfo } = community;
  // if (propInfo) {
  //   const { typeCare: typeCares } = propInfo;
  //   if (typeCares) {
  //     return typeCares.length === 1 && validTocLabels.includes(typeCares[0]);
  //   }
  // }
  return getIsActiveAdult(community);
};

export const formatAddress = address => {
  if (!address) return '';

  return ['line1', 'city', 'state']
    .reduce((acc, attr) => {
      if (address[attr]) {
        acc.push(address[attr]);
      }
      return acc;
    }, [])
    .join(', ');
};

// Faf = Friends and Family
export const showFafNumber = address => {
  if (!address || !address.zipcode || !address.zipcode.cityTier) return false;
  return address.zipcode.cityTier === '1';
};

export const getFafNumber = (communityPhone = '') => {
  // Hard coded business logic ( only for tier 1
  const tier1Nums = ['4153004354', '6506845456'];
  let fafn = tier1Nums[0];
  const foundNum = tier1Nums.find(
    num => areaCode(num) === areaCode(communityPhone)
  );
  if (foundNum) {
    fafn = foundNum;
  }
  return fafn;
};

const getStateLink = (addressState, licenseNumber, rgsInfo) => {
  if (addressState === 'CA' && licenseNumber !== '') {
    return `https://www.ccld.dss.ca.gov/carefacilitysearch/FacDetail/${licenseNumber}`;
  } else if (addressState === 'CA') {
    return 'https://www.cdss.ca.gov/inforesources/community-care-licensing';
  } else if (addressState === 'FL') {
    const { scoreParams = { detailUrl: '' } } = rgsInfo;
    const { detailUrl } = scoreParams;
    if (detailUrl !== '') {
      return detailUrl;
    }
    return 'https://www.floridahealthfinder.gov/facilitylocator/facloc.aspx';
  }
  return 'https://www.ahcancal.org/Assisted-Living/Policy/Pages/state-regulations.aspx';
};

// valid types : stateScore
export const getTrustScoreType = (community, scoreType) => {
  const {
    name,
    propInfo: { licenseNumber = '' },
    rgsAux = { rgsInfo: {} },
    address: { state }
  } = community;
  const { rgsInfo = {} } = rgsAux;
  const {
    trustScore,
    scoreParams = { lastInspectionDate: '', licensedDate: '' }
  } = rgsInfo;
  const ld = formatDate(scoreParams.licensedDate);
  const lastLicensedDate = ld.match(/invalid/) ? 'unknown date' : ld;
  const lid = formatDate(scoreParams.lastInspectionDate);
  const lastInspectionDate = ld.match(/invalid/) ? 'unknown date' : lid;
  const fullStateName = stateNames[state];
  const licensingUrl = getStateLink(state, licenseNumber, rgsInfo);
  const linkText = `To learn more, visit the state licensing authority for ${name}`;
  const { value1 = '', value2 = '', value3 = '' } = scoreParams;
  // FIXME: Api has a bug where it switches value2 inplace of value3.
  const prop1 = value1 === '' ? `Licensed since ${lastLicensedDate}` : value1;
  const prop2 =
    value3 === '' ? `Most recent inspection on ${lastInspectionDate}` : value3;
  let prop3 = `Has fewer complaints relative to communities in ${fullStateName}`;
  if (trustScore < 71) {
    prop3 = `Has more complaints relative to communities in ${fullStateName}`;
  }
  prop3 = value2 === '' ? prop3 : value2;
  let valueText = 'Excellent';
  if (trustScore > 70 && trustScore < 81) {
    valueText = 'Good';
  } else if (trustScore > 50 && trustScore < 71) {
    valueText = 'Okay';
  } else if (trustScore < 51) {
    valueText = 'Poor';
  }
  const moreInfoText =
    "The Seniorly Trust Score rates a community's compliance with state regulations and other key factors that indicate overall quality and responsible management on a scale of 0 to 100, where 100 is the highest score. Learn more about this community on ";
  const trustScores = {
    stateScore: {
      value: trustScore,
      prop1,
      prop2,
      prop3,
      moreInfoText,
      linkText,
      licensingUrl,
      valueText
    }
  };
  // livabilityScore: {} }; // Can add other types of score in the future.
  // Next iteration: GET REQUEST TO https://www.ccld.dss.ca.gov/transparencyapi/api/EmailSubscribe?facNum=015600130&Semail=sushanthr+testccld@gmail.com

  return trustScores[scoreType];
};

export const getAgentTestimonial = (community, partnerAgent) => {
  const { recommendedAgents } = community;
  //filtering out if partnerAgent is present in recommendedAgents
  const agents =
    partnerAgent &&
    recommendedAgents &&
    recommendedAgents.filter(e => e.info.agentSlug === partnerAgent.id);
  const agent = agents && agents.length > 0 ? agents[0] : null;
  return agent && agent.info ? agent.info.testimonial : null;
};

export const getAgentFirstName = agent => {
  if (
    agent &&
    agent.info &&
    agent.info.displayName &&
    agent.info.displayName !== ''
  ) {
    return agent.info.displayName.split(' ')[0];
  }
  // if empty return generic term
  return 'Advisor';
};

export const getChatBotEventName = community => {
  const {
    propInfo: { tier },
    address: { state }
  } = community;
  const partnerAgent = getPartnerAgent(community);
  const hasContract = getHasContract(community);
  let eventName = 'Bot reintro'; // Default event name

  // NON-CCRC profile pages, EXCEPT: in no agent areas or Emma's zip codes
  if (!getIsCCRC(community) && (partnerAgent || tier !== '1')) {
    eventName = 'standard-non-direct';
  }
  // On all profile pages in Emma's zip codes
  if (tier === '1' && state === 'IL') {
    eventName = 'emma-direct-market';
  }

  // On all profile pages in Lauren's zip codes
  if (tier === '1' && state === 'CA') {
    eventName = 'lauren-direct-market';
  }
  // All CCRC profile pages pages EXCEPT: in no agent area or Emma's zip codes or CCRCs we have a contract with
  if (getIsCCRC(community) && (partnerAgent || tier !== '1' || !hasContract)) {
    eventName = 'ccrc-profile';
  }
  // on all profile pages in no agent areas EXCEPT: if we have a contract with community
  if (!partnerAgent && !hasContract) {
    eventName = 'user-intent';
  }
  return eventName;
};

export const isEntityVerified = entity => {
  const { propInfo = {}, tags = [] } = entity;
  if (propInfo.isVerified) {
    return true;
  }
  return !!tags?.includes(VERIFIED_TAG);
};

export const isEntityPlus = entity => {
  const { propInfo = {}, tags = [] } = entity;
  if (propInfo.plusCommunity) {
    return true;
  }
  return !!tags?.includes(PLUS_TAG);
};

export const getTagsDetailsReact = ({
  isVerified,
  isNewConstruction,
  isAward
}) => {
  const tags = [];
  if (isNewConstruction) {
    const tag = {};
    tag.text = 'New Development';
    tag.iconColor = 'text-yellow-base';
    tag.Icon = ConstructionTag2React;
    //bg-[#FDEDD9]
    tag.textBgColor = 'bg-white';
    //text-[#B46100]
    tag.textColor = 'text-[#B46100]';
    tags.push(tag);
  } else if (isVerified) {
    const tag = {};
    tag.text = 'Verified';
    tag.iconColor = 'text-green-base';
    tag.Icon = Verified2React;
    tag.textBgColor = 'bg-white';
    //text-[#008815]
    tag.textColor = 'text-green-base';
    tags.push(tag);
  }

  if (isAward) {
    const awardTag = {};
    awardTag.text = `Best of ${SENIORLY_AWARD_YEAR_COMMUNITIES}`;
    awardTag.iconColor = 'text-viridian-base';
    awardTag.Icon = BestReact;
    awardTag.textBgColor = 'bg-white';
    awardTag.textColor = 'text-viridian';
    tags.push(awardTag);
  }

  return tags;
};

export const getTagDetails = ({ isVerified, isNewConstruction }) => {
  const tag = {};
  if (isNewConstruction) {
    tag.text = 'New Development';
    tag.iconColor = 'text-yellow-base';
    tag.Icon = Construction;
    tag.textBgColor = 'bg-white';
    tag.textColor = 'text-[#B46100]';
  } else if (isVerified) {
    tag.text = 'Verified';
    tag.iconColor = 'text-green-base';
    tag.Icon = Verified;
    tag.textBgColor = 'bg-white';
    tag.textColor = 'text-green-base';
  }
  return tag;
};

const getLatestInspectionData = inspectionDetails => {
  if (inspectionDetails?.length) {
    const inspections = inspectionDetails
      .map(inspection => {
        return {
          ...inspection,
          inspectionTs: inspection.inspectionDate
            ? new Date(inspection.inspectionDate).getTime()
            : 0
        };
      })
      .sort((a, b) => {
        return (a.inspectionTs = b.inspectionTs);
      });
    return inspections[inspections.length - 1];
  }
  return null;
};

const getStateExternalCommunityInfo = (externalCommunityInfo, state) => {
  if (state === 'CA') {
    return externalCommunityInfo?.info?.californiaInfo;
  } else if (state === 'TX') {
    return externalCommunityInfo?.info?.texasInfo;
  } else if (state === 'FL') {
    return externalCommunityInfo?.info?.floridaInfo;
  } else if (state === 'PA') {
    return externalCommunityInfo?.info?.pennsylvaniaInfo;
  }
};

export const getLicensingContent = ({ community }) => {
  const { externalCommunityInfo, address } = community;
  const { state } = address;
  const stateExternalCommunityInfo = getStateExternalCommunityInfo(
    externalCommunityInfo,
    state
  );

  const result = {
    licenseNumber: externalCommunityInfo?.licenseNumber,
    licenseUrl: externalCommunityInfo?.info?.licenseURL,
    licenseStatus: stateExternalCommunityInfo?.licenseStatus,
    licensedCapacity: externalCommunityInfo?.info?.facilityCapacity,
    lastInspectionReport: getLatestInspectionData(
      stateExternalCommunityInfo?.inspectionDetails
    )?.url,
    alzheimerCapacity: stateExternalCommunityInfo?.alzheimerCapacity,
    serviceHighlightText: stateExternalCommunityInfo?.serviceHighlightText,
    serviceHelpText: stateExternalCommunityInfo?.serviceHelpText,
    typeOfEntity: stateExternalCommunityInfo?.typeOfEntity,
    typeOfOperation: stateExternalCommunityInfo?.typeOfOperation,
    certificateURL: stateExternalCommunityInfo?.certificateURL,
    typeOfLicense: stateExternalCommunityInfo?.typeOfLicense
  };
  Object.keys(result).forEach(key => {
    if (!result[key]) {
      delete result[key];
    }
  });
  return result;
};

export const getInsightsCategories = communityInsights => {
  const categoriesSet = new Set();
  communityInsights
    ?.map(item => item.category)
    .filter(category => !!category)
    .forEach(category => {
      categoriesSet.add(category);
    });
  return Array.from(categoriesSet);
};

export const getDefaultCommunityImageUrl = (
  id,
  communitySize = 'up to 20 Beds',
  oneByOne = false
) => {
  const obj = oneByOne
    ? communityDefaultImageTemplates1x1
    : communityDefaultImageV2Templates;

  return assetPath(obj[communitySize]?.[getHashInRange(id, 3)]);
};

export const shouldShowBackgroundCheck = (state, care) => {
  if (care?.includes(SKILLED_NURSING_FACILITY)) {
    return true;
  }

  return (
    (state === 'CA' || state === 'FL' || state === 'TX') &&
    (care?.includes(ASSISTED_LIVING) ||
      care?.includes(INDEPENDENT_LIVING) ||
      care?.includes(MEMORY_CARE))
  );
};
