import { getDisclosureEnabledStatesInD2CGwizzyCommunities } from '@common/helpers/communities';
import { haversineDistance } from '@common/helpers/haversineDistance';
import { recordEntityCta } from '@common/helpers/localStorage';
import { ConversionSchemaType } from '@common/helpers/transformGwizzyCommunity';
import { objectToURLQueryParams } from '@common/helpers/url';
import { getModalFromEntry } from '@common/helpers/wizard';
import { hasUserAcknowledgedDisclosureByState } from '@react/cta/helpers';
import getReferredCommunities from '@react/cta/helpers/getReferredCommunities';
import { getIsLowIncomeFromConversionData } from '@react/gwizzy/helpers/utils';
import { useReducerAsync } from '@react/lib/useReducerAsync';
import { normalizeResponse, useQuery } from '@react/services/api';
import { useUser } from '@react/services/auth';
import { eventNames, useEvents } from '@react/services/events';
import { careLevelToTocMap } from '@react/widgets/pricingCalculator/constants';
import {
  ASSESSMENT_WIZARD_COMPLETED,
  ASSESSMENT_WIZARD_COMPLETED_COMMUNITIES,
} from '@sly/core/constants/assessment';
import { useCallback, useMemo } from 'react';
import { isBrowser } from 'sly/config';

const RESET = 'RESET';
const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
const SET_CONVERSION_DATA = 'SET_CONVERSION_DATA';
const SET_IS_MOBILE = 'SET_IS_MOBILE';
const SET_RECOMMENDED_COMMUNITIES = 'SET_RECOMMENDED_COMMUNITIES';
const SET_REFERRED_AGENT = 'SET_REFERRED_AGENT';
const SET_ENABLED_DISCLOSURE_STATE = 'SET_ENABLED_DISCLOSURE_STATE';
const SET_USER_OPTED_COMMUNITIES = 'SET_USER_OPTED_COMMUNITIES';

const SET_REFERRED_COMMUNITIES = 'SET_REFERRED_COMMUNITIES';
const MODALS = {
  conversion: 'conversion',
  postConversion: 'postConversion',
  recommendedCommunities: 'recommendedCommunities',
  advisor: 'advisor',
  d2cConfirmation: 'd2cConfirmation',
  disclosure: 'disclosure',
};
const SET_RECOMMENDED_REASONS = 'SET_RECOMMENDED_REASONS';

export const initialState = {
  currentModal: '',
  conversionData: null,
  isMobile: false,
  recommendedCommunities: [],
  recommendedReasons: [],
  referredCommunities: [],
  referredAgent: null,
  enabledDisclosureState: '',
  userOptedCommunities: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_MODAL:
      return { ...state, currentModal: action.payload };
    case SET_CONVERSION_DATA:
      return { ...state, conversionData: action.payload };
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case SET_RECOMMENDED_COMMUNITIES:
      return { ...state, recommendedCommunities: action.payload };
    case SET_RECOMMENDED_REASONS:
      return { ...state, recommendedReasons: action.payload };
    case SET_REFERRED_COMMUNITIES:
      return { ...state, referredCommunities: action.payload };
    case SET_REFERRED_AGENT:
      return { ...state, referredAgent: action.payload };
    case SET_ENABLED_DISCLOSURE_STATE:
      return { ...state, enabledDisclosureState: action.payload };
    case SET_USER_OPTED_COMMUNITIES:
      return { ...state, userOptedCommunities: action.payload };
    case RESET:
      return initialState;

    default:
      throw new Error('no such action type');
  }
};

export default function useGwizzyCta(props) {
  const {
    mode,
    ctaProps,
    gwizzyCommunity: community,
    isOpenByDefault,
    onCompletion,
    onClose,
    userOptedCommunities,
    disclosureState,
    conversionSchemaOverride,
  } = props;
  const isD2C = !!community?.isD2C;
  const { user } = useUser();
  const isAutoReferable = community?.isAutoReferable;

  const { prop = 'redirect', contactInfo, enableOneToMany } = ctaProps;
  const { events } = useEvents();

  const getHomeBase = useQuery('getHomeBase');
  const {
    id,
    name,
    city,
    state: communityState,
    latitude,
    longitude,
  } = community || {};
  const [state, dispatch] = useReducerAsync(
    '',
    reducer,
    initialState,
    (initial = initialState) => {
      if (isBrowser && isOpenByDefault) {
        events.track(eventNames.WizardStarted, {
          location: 'gwizzy',
          communitySlug: id,
          name,
          city,
          state: communityState,
        });
        events.track(eventNames.ButtonClicked, props?.eventProps);
      }
      return {
        ...initial,
        currentModal: isOpenByDefault ? MODALS.conversion : '',
        enabledDisclosureState:
          community?.isDisclosureEnabled &&
          !hasUserAcknowledgedDisclosureByState(user, community?.state)
            ? community?.state
            : disclosureState
            ? disclosureState
            : '',
        userOptedCommunities: userOptedCommunities,
      };
    },
    {}
  );

  const { conversionData } = state;

  const handleRedirectToHomebase = useCallback(async () => {
    if (mode) {
      const qp = {};
      const modal = getModalFromEntry(mode.entry);
      if (modal) {
        qp.modal = modal;
      }
      if (community?.name) {
        qp.communityName = community.name;
      }
      if (community?.isAutoReferable) {
        qp.isAutoReferable = 'true';
      }
      const redirectPath = `/homebase?${objectToURLQueryParams(qp)}`;

      if (isBrowser) {
        window.location.assign(redirectPath);
      }
    }
  }, [community?.name, mode]);

  const handleCloseModal = useCallback(() => {
    dispatch({ type: SET_CURRENT_MODAL, payload: '' });
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  const conversionStart = useCallback(() => {
    events.track(eventNames.WizardStarted, {
      location: 'gwizzy',
      communitySlug: id,
      name,
      city,
      state: communityState,
    });

    dispatch({
      type: SET_CURRENT_MODAL,
      payload: MODALS.conversion,
    });
  }, [city, communityState, dispatch, events, id, name]);

  const handleGetHomebase = useCallback(async () => {
    let homeBase = null;
    try {
      const response = await getHomeBase({
        id: 'me',
        'filter[d2c]': 'eq:true',
        include: 'all',
      });
      homeBase = await normalizeResponse(response.body);
    } catch (e) {
      console.error(e);
    }
    return homeBase;
  }, [getHomeBase]);

  const handleOnComplete = useCallback(async () => {
    if (onCompletion) {
      await onCompletion(state);
    }
  }, [onCompletion, state]);

  const getAndSetReferredCommunities = useCallback(
    (homebase) => {
      if (!homebase) {
        return null;
      }

      const referredCommunities = getReferredCommunities(homebase);

      if (!referredCommunities?.length) {
        return null;
      }

      dispatch({
        type: SET_REFERRED_COMMUNITIES,
        payload: referredCommunities,
      });
      return referredCommunities;
    },
    [dispatch]
  );

  const getAndSetReferredAgent = useCallback(
    (homebase) => {
      if (!homebase) {
        return null;
      }

      const agent = homebase?.agent;
      if (!agent) {
        return null;
      }

      dispatch({
        type: SET_REFERRED_AGENT,
        payload: agent,
      });
      return agent;
    },
    [dispatch]
  );

  const conversionDone = useCallback(
    async (values, isSignUpSuccessfull) => {
      dispatch({ type: SET_CONVERSION_DATA, payload: values });
      recordEntityCta(ASSESSMENT_WIZARD_COMPLETED_COMMUNITIES, id);
      localStorage.setItem(
        ASSESSMENT_WIZARD_COMPLETED,
        ASSESSMENT_WIZARD_COMPLETED
      );

      if (community?.primaryToc?.includes('active-adult')) {
        handleCloseModal();
        await handleOnComplete();
        return;
      }

      if (isSignUpSuccessfull) {
        const homebase = await handleGetHomebase();
        getAndSetReferredAgent(homebase);
        if (enableOneToMany) {
          const recommendedCommunities = homebase?.recommendedCommunities || [];
          const recommendationReasons = homebase?.recommendationReasons || {};

          const formattedCommunities = recommendedCommunities
            .filter((community) => community.id !== id)
            .map((community) => ({
              ...community,
              distance:
                !!longitude && !!latitude
                  ? Number.parseFloat(
                      haversineDistance(
                        [
                          community?.address?.longitude,
                          community?.address?.latitude,
                        ],
                        [longitude, latitude],
                        true
                      )
                    ).toFixed(1)
                  : null,
            }))
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 3);

          const uniqueReasons = Object.entries(recommendationReasons).reduce(
            (acc, [key, value]) => {
              if (
                formattedCommunities.some(
                  (community) => community.id === key
                ) &&
                !acc.includes(value)
              ) {
                acc.push(value);
              }
              return acc;
            },
            []
          );

          const hasUserSelectedNone =
            values?.nextStep === 'none' &&
            values?.similarCommunities?.length > 0;

          // if recommended communities exist, show recommended communities modal
          if (!hasUserSelectedNone && formattedCommunities.length) {
            dispatch({
              type: SET_RECOMMENDED_COMMUNITIES,
              payload: formattedCommunities,
            });
            dispatch({
              type: SET_RECOMMENDED_REASONS,
              payload: uniqueReasons,
            });
            dispatch({
              type: SET_CURRENT_MODAL,
              payload: MODALS.recommendedCommunities,
            });
            return;
          }
        }

        if (state.enabledDisclosureState) {
          dispatch({
            type: SET_CURRENT_MODAL,
            payload: MODALS.disclosure,
          });
          return;
        }

        if (isD2C) {
          const referredCommunities = getAndSetReferredCommunities(homebase);
          if (referredCommunities && referredCommunities?.length) {
            dispatch({
              type: SET_CURRENT_MODAL,
              payload: MODALS.d2cConfirmation,
            });
            return;
          }
        }

        if (prop === 'homebase') {
          handleRedirectToHomebase();
          handleCloseModal();
          await handleOnComplete();
          return;
        }
      }

      dispatch({
        type: SET_CURRENT_MODAL,
        payload: MODALS.advisor,
      });

      return;
    },
    [
      dispatch,
      id,
      handleGetHomebase,
      getAndSetReferredAgent,
      enableOneToMany,
      state.enabledDisclosureState,
      isD2C,
      prop,
      longitude,
      latitude,
      getAndSetReferredCommunities,
      handleRedirectToHomebase,
      handleCloseModal,
      handleOnComplete,
      community?.primaryToc,
    ]
  );

  // confirmation action for disclosure modal
  const handleConfirmationScreen = useCallback(async () => {
    const homebase = await handleGetHomebase();
    const referredCommunities = getAndSetReferredCommunities(homebase);

    if (referredCommunities?.length) {
      dispatch({ type: SET_CURRENT_MODAL, payload: MODALS.d2cConfirmation });
    } else {
      dispatch({ type: SET_CURRENT_MODAL, payload: MODALS.advisor });
    }
  }, [dispatch, getAndSetReferredCommunities, handleGetHomebase]);

  const rcDone = useCallback(
    async (props) => {
      const { disclosureState: o2mDisclosureState } = props || {};
      // This check will consider the case where either converted community is d2c enabled or the o2m communities
      const disclosureState =
        o2mDisclosureState || state.enabledDisclosureState;
      if (
        disclosureState &&
        !hasUserAcknowledgedDisclosureByState(user, disclosureState)
      ) {
        dispatch({
          type: SET_CURRENT_MODAL,
          payload: MODALS.disclosure,
        });
        dispatch({
          type: SET_ENABLED_DISCLOSURE_STATE,
          payload: disclosureState,
        });
        return;
      }

      if (prop === 'homebase' && !isAutoReferable) {
        handleCloseModal();
        handleRedirectToHomebase();
        return;
      }

      await handleConfirmationScreen();
    },
    [
      dispatch,
      handleCloseModal,
      handleConfirmationScreen,
      handleRedirectToHomebase,
      isAutoReferable,
      prop,
      state.enabledDisclosureState,
      user,
    ]
  );

  const recommendationPricingRequestDone = useCallback(async () => {
    if (!user) {
      dispatch({
        type: SET_CURRENT_MODAL,
        payload: MODALS.conversion,
      });
      return;
    }

    const disclosureState = getDisclosureEnabledStatesInD2CGwizzyCommunities(
      state?.userOptedRcCommunities || []
    )?.[0];
    if (
      disclosureState &&
      !hasUserAcknowledgedDisclosureByState(user, disclosureState)
    ) {
      dispatch({
        type: SET_ENABLED_DISCLOSURE_STATE,
        payload: disclosureState,
      });
      return;
    }

    if (prop === 'homebase' && !isAutoReferable) {
      handleCloseModal();
      handleRedirectToHomebase();
      return;
    }
    await handleConfirmationScreen();
  }, [
    dispatch,
    handleCloseModal,
    handleConfirmationScreen,
    handleRedirectToHomebase,
    isAutoReferable,
    prop,
    user,
    state?.userOptedRcCommunities,
  ]);

  const advisorConfirmationDone = useCallback(async () => {
    const isLowIncome = getIsLowIncomeFromConversionData({
      maxMonthlyBudget: conversionData?.budgetRange,
      payment: conversionData?.budget,
    });

    if (
      conversionSchemaOverride === ConversionSchemaType.PaidAd &&
      conversionData?.adl &&
      conversionData?.location?.searchParams?.state &&
      conversionData?.location?.searchParams?.city
    ) {
      window.location.href = `/${careLevelToTocMap[conversionData?.adl]}/${
        conversionData?.location?.searchParams?.state
      }/${conversionData?.location?.searchParams?.city}`;
      return;
    }

    if (!isLowIncome) {
      dispatch({ type: SET_CURRENT_MODAL, payload: MODALS.postConversion });
    } else {
      handleCloseModal();
      await handleOnComplete();
    }
  }, [
    conversionData?.budgetRange,
    conversionData?.budget,
    dispatch,
    handleCloseModal,
    handleOnComplete,
    conversionSchemaOverride,
    conversionData?.adl,
    conversionData?.location?.searchParams?.state,
    conversionData?.location?.searchParams?.city,
  ]);

  const d2cConfirmationDone = useCallback(() => {
    dispatch({ type: SET_CURRENT_MODAL, payload: MODALS.postConversion });
    return;
  }, [dispatch]);

  const postConversionDone = useCallback(async () => {
    handleCloseModal();
    await handleOnComplete();
  }, [handleCloseModal, handleOnComplete]);

  const types = useMemo(
    () => ({
      setCurrentModal: SET_CURRENT_MODAL,
    }),
    []
  );

  const handleClose = useCallback(() => {
    dispatch({ type: RESET });
    onClose?.();
  }, [dispatch, onClose]);

  const setIsMobile = useCallback(
    (isMobile) => {
      dispatch({ type: SET_IS_MOBILE, payload: isMobile });
    },
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      conversionStart,
      conversionDone,
      handleClose,
      advisorConfirmationDone,
      postConversionDone,
      setIsMobile,
      rcDone,
      d2cConfirmationDone,
      handleConfirmationScreen,
      recommendationPricingRequestDone,
    }),
    [
      conversionStart,
      conversionDone,
      handleClose,
      advisorConfirmationDone,
      postConversionDone,
      setIsMobile,
      rcDone,
      d2cConfirmationDone,
      handleConfirmationScreen,
      recommendationPricingRequestDone,
    ]
  );

  return {
    isShowingConversion: state.currentModal === MODALS.conversion,
    isShowingPostConversion: state.currentModal === MODALS.postConversion,
    isShowingAdvisorModal: state.currentModal === MODALS.advisor,
    isShowingRCModal: state.currentModal === MODALS.recommendedCommunities,
    isShowingD2CConfirmationModal:
      state.currentModal === MODALS.d2cConfirmation,
    isShowingDiscloureModal: state.currentModal === MODALS.disclosure,
    dispatch,
    actions,
    types,
    state,
    contactInfo,
  };
}
